var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Optional Groups "),_c('v-spacer')],1),_c('v-card-title',{staticClass:"pb-0"},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"loading":_vm.loadingGrade,"disabled":_vm.grades.length < 1,"items":_vm.grades,"outlined":"","dense":"","label":"Select grade"},on:{"change":function($event){_vm.getSections(), _vm.getSubjects(), _vm.getOptionalList()}},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"loading":_vm.loadingSection,"disabled":_vm.sections.length < 1,"items":_vm.sections,"outlined":"","dense":"","label":"Select section"},on:{"change":function($event){return _vm.getOptionalList()}},model:{value:(_vm.section),callback:function ($$v) {_vm.section=$$v},expression:"section"}})],1)],1),_c('v-container',{staticClass:"subject_list_group"},[(_vm.selectedGrades && _vm.subjectList.length > 0)?_c('h4',{staticStyle:{"color":"#777","font-weight":"normal"}},[_vm._v(" Subject List for "),_c('strong',{staticStyle:{"color":"#444"}},[_vm._v(_vm._s(_vm.selectedGrades)+" "),(_vm.selectedSection)?_c('span',[_vm._v("("+_vm._s(_vm.selectedSection)+")")]):_vm._e()]),_vm._v(" "),(_vm.hasOptionalSubjects)?_c('small',[_vm._v("(Click to select the highlighted optional subject.) ")]):_vm._e()]):_vm._e(),_vm._l((_vm.subjectList),function(subject,key){return _c('span',{key:key},[(subject.sub_type === 'comp')?_c('v-chip',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(subject.name)+" ")]):(subject.sub_type === 'opt')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"mr-2 mt-2",attrs:{"color":"primary","text-color":"white"},on:{"click":function($event){return _vm.selectOptSubject(subject)}}},on),[(_vm.selectedOptSubjects.indexOf(subject) > -1)?_c('v-avatar',[_c('v-icon',[_vm._v("check_circle")])],1):_vm._e(),_vm._v(" "+_vm._s(subject.name)+" ("),_c('strong',[_vm._v(_vm._s(_vm.getTotalNumberOfStudentEachOptSubject(subject.id))+")")])],1)]}}],null,true)},[_c('span',[_vm._v("Click to filter "+_vm._s(subject.name)+" students")])]):_vm._e()],1)}),(_vm.subjectList.length)?_c('span',[_c('br'),_c('br')]):_vm._e(),(_vm.subjectList.length > 0 && !_vm.hasOptionalSubjects)?_c('alert-message',{attrs:{"type":"error","title":"Optional Subject Alert"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('strong',[_vm._v("Warning!!")]),_vm._v(" No optional subjects has been specified for this grade "),_c('strong',[_vm._v("("+_vm._s(_vm.selectedGrades)+")")]),_vm._v(" yet. Please specify some optional subjects and try again. ")]},proxy:true}],null,false,166529523)}):_vm._e(),_c('v-data-table',{staticStyle:{"margin-top":"15px"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.allStudent,"options":_vm.pagination,"hide-default-footer":"","footer-props":_vm.footerProps,"server-items-length":_vm.allStudent.length},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(item.roll)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-right"},_vm._l((_vm.subjectList),function(subject,key){return _c('span',{key:key},[(subject.sub_type === 'opt')?_c('v-chip',{staticClass:"mr-2",attrs:{"color":item.subjects.indexOf(subject.id) > -1
                          ? 'primary'
                          : '',"text-color":item.subjects.indexOf(subject.id) > -1 ? 'white' : ''},on:{"click":function($event){return _vm.addOrRemoveOptionalSubject(item, subject)}}},[(item.subjects.indexOf(subject.id) > -1)?_c('v-avatar',[_c('v-icon',[_vm._v("check_circle")])],1):_vm._e(),_vm._v(" "+_vm._s(subject.name)+" ")],1):_vm._e()],1)}),0)])]}}])}),_c('br'),_c('br'),(_vm.allStudent.length > 0 && _vm.isCurrentBatch)?_c('edit-button',{attrs:{"loading":_vm.saving,"editText":"Update","disabled":!_vm.isChanged || _vm.saving || !_vm.allStudentHaveOptionalSubjects,"label":"Update student's optional subjects","permission":"student-update","large":true,"block":true},on:{"agree":_vm.updateOptionalList}}):_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }